<template>
  <div class="page">
    <!--标题-->
    <ls-title title="使用记录"></ls-title>
    <!-- 条件框 -->
    <div class="conddiv">
      <span></span>
      <el-select v-model="commonType" class="m-2" size="large">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="createTime" label="日期时间" width="180" label-class-name="tablehead" align="center " />
      <el-table-column v-if="role == 1" prop="unitName" label="机构名称" label-class-name="tablehead" align="center " />
      <el-table-column prop="useType" label="使用类型" label-class-name="tablehead" align="center " >
        <template #default="scope">
          <span v-if="scope.row.useType == 1">短信</span>
          <span v-if="scope.row.useType == 2">语音</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话号码" label-class-name="tablehead" align="center " />
      <el-table-column prop="content" label="内容信息" label-class-name="tablehead" align="center " />
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
</template>

<script>
import {getUseLogInfos } from '../../../api/api'
import session from "../../../store/store";

export default {
  data() {
    return {
      emptyText:'加载中',
      options: [
        {value: 0,label: '全部'},
        {value: 1,label: '短信'},
        {value: 2,label: '语音'}
      ],
      commonType:0,
      currentPage: 1,
      size: 10,
      total: 0,
      role: session.getLoginInfo().accountType,
      tableData: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getUseLogInfos();
    },
    getUseLogInfos() {
      var uuidType = 1
      if(this.role == 1) {
        uuidType = 1
      }else {
        uuidType = 2
      }
      var param = {
        uuid:session.getLoginInfo().unitUuid,
        uuidType:uuidType,
        page:this.currentPage - 1,
        size:this.size,
        commonType:this.commonType
      }
      getUseLogInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    search() {
      this.getUseLogInfos();
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUseLogInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUseLogInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>